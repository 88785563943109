// Here you can add other styles

body {
  font-family: 'Open Sans', sans-serif;
}

// .c-app{
//   background: white !important;
// }

.bg-ground{
 background: white !important;
}


@media (min-width: 768px) {
  .c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl, .c-main > .container-xxl {
      padding-bottom: 12px !important;
  }
}

@media (max-width: 768px) {
.c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl, .c-main > .container-xxl {
  padding-bottom: 15px !important;
}
}


select {
  font-family: "Open Sans", Tahoma, Helvetica, Arial, sans-serif;
  font-size: 12px;
  /* float: right; */
  color: #414141;
  height: 22px;

  width: 66%;
  border: 1px solid #d0d6e1;
  outline: none;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0 28px 0 7px !important;
  //  background-image: url("../assets/resource/icon_ddl.png");
   background-image: url("../assets/resource/dropdown-icon.png");
  background-position: right;
  background-repeat: no-repeat;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-left: auto;
}



.c-sidebar .c-sidebar-brand{
  background-color: transparent;
  background: linear-gradient(0deg, #E9EFFB, #E9EFFB) !important ;

}

c-sidebar .c-sidebar-brand{
  background-color: transparent !important;
  
}

.c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle{
// color: black !important;
transition: none !important;
color: #fff;
}

.c-sidebar-brand {
  flex: 0 0 90px !important;

}

.c-sidebar {
  color: #fff;
  background: white !important;
}

.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover{
  background: #216ED0!important;
  color: white !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.c-sidebar .c-sidebar-minimizer::before {
   background-image: url('../assets/resource/icons/collapse-icon.png') !important;
   background-size: 20px !important;
   height: 37px !important;
}

.c-sidebar-minimizer
{
  flex: 0 0 35px !important;
}


@media (min-width: 992px) {
  .c-sidebar-minimized .c-sidebar-minimizer {
      position: relative;
      bottom: 0;
      width: inherit;
  }
}


.c-body{
  background: #fff !important;
}
.HomeIcon{
  margin-right: 15px !important;
//  width: 50px !important;
//  height: 23px !important;
 width: 16px;
  // background: url('../assets/resource/icons/Home_normal.png') no-repeat 0 0;
}

.WorkQueueIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/WorkQueue_normal.png') no-repeat 0 0;
 }
 
 .DailySubmissionIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/daily_normal.png') no-repeat 0 0;
 }
 
 .ClaimsIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/claim_normal.png') no-repeat 0 0;
 }
 
 .ERAIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/era_normal.png') no-repeat 0 0;
 }
 
 .ProvEnrollIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/provider_normal.png') no-repeat 0 0;
 }

 .ReportIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/report_normal.png') no-repeat 0 0;
 }

 .PayerIcon{
  width: 50px !important;
  height: 30px !important;
  background: url('../assets/resource/icons/payer_normal.png') no-repeat 0 0;
 }
 

// .c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover :nth-child(1) {
//   // background-color: red !important;

//   width: 50px;
//   height: 50px;
//    background: url('../assets/resource/icons/Home_normal.png') no-repeat 0 0 !important;
//  }


.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle{
  background:  #1D57A1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
  ///  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
-webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) ;
font-weight: 700;

}

.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle
{
  padding: 5px 12px !important;

}


.c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff !important;

}


.c-sidebar-nav-icon{
color: black !important;
}


.c-sidebar .c-sidebar-minimizer {
  background-color: #2988E5;
 
}

.c-sidebar .c-sidebar-minimizer:hover {
  background-color: #2988E5;
};

.c-sidebar-nav.ps {
  background-color: #2988E5;
  margin-top: 6px;
  margin-left: 6px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 6px 10px 0px 0px;
}


.c-sidebar-minimized .c-sidebar-nav {
  padding-bottom: 50px;
  overflow: visible;
  background: #2988E5;
  border-radius: 10px;
  margin-left: 4px;
  margin-top: 6px;
  
}


.c-sidebar-minimized .c-sidebar-nav .HomeIcon
{
  margin-right: 26px !important;
  width: 16px;
  margin-left: 4px;
}
a.dropdown-item{
  // background-color: #7da9ae !important;
  // border-color: #7da9ae !important;
  font-size: 13px !important;
  font-style: normal !important;
}

// a.dropdown-item:link{
//   background-color: #7da9ae !important;
//   border-color: #7da9ae !important;
// }

.dropdown-menu.show {
  display: block;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding-top: .5rem !important;
}

a.dropdown-item:hover{
  // background-color: #4a93e0 !important;
  // border-color: #4a93e0 !important;
  color: black;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background: #E9F2FA;
}

a.dropdown-item:active{
  background-color: #e9e9e9 !important;
  border-color: #e9e9e9 !important;
}
a.dropdown-item:focus{
  // background-color: #7da9ae !important;
  // border-color: #7da9ae !important;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background: #d9e9f8 !important;
    border-color:#d9e9f8 !important;
}

.c-header-nav .dropdown-item {
   min-width: 161px; 
}

// a.dropdown-item:visited{
//   background-color: #7da9ae !important;
//   border-color: #7da9ae !important;
// }


.custom_button1{
  // background-color: #026f7b !important;
  // border-color: #7da9ae !important;
  height: fit-content;

  background-color: #a9c23f !important;
  border: 1px solid #a9c23f !important;
  border-radius: 15px !important;
  cursor: pointer !important;
  float: right !important;
  font: 13px Open Sans, sans-serif !important;
  // height: 22px !important;
  margin-right: 4px !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #FFF !important;
  height: 27px !important;
  padding: 4px 19px !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  outline: none !important;
  cursor: pointer !important;
  outline: none !important;
  appearance: none !important;
  background: #a9c23f !important;
  border: 1px solid #a9c23f !important;
  text-align: center !important;
  border-radius: 15px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;

  }

.custom_button{
  // background-color: #026f7b !important;
  // border-color: #7da9ae !important;

    background-color: #458bd5 !important;
    border: none !important;
    border-radius: 15px !important;
    cursor: pointer !important;
    float: right !important;
    font: 13px Open Sans, sans-serif;
    height: 22px !important;
    margin-right: 4px !important;
    padding: 0 10px !important;
    text-decoration: none!important;
    margin-top: 4px !important;

  }


  .custom_button-ChangePS-Primary{
    // background-color: #026f7b !important;
    // border-color: #7da9ae !important;
  
    background-color: #a9c23f !important;
    /* border: none !important; */
    border-radius: 15px !important;
    cursor: pointer !important;
    font: 12px Open Sans, sans-serif;
    height: 30px !important;
    text-decoration: none !important;
    margin-top: 3px !important;
    width: auto !important;
    border: 1px solid #a9c23f;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-weight: 600;
    margin-right: 5px;
    margin-left: 5px;

  
    }

    .custom_button-ChangePS-Secondary{
      // background-color: #026f7b !important;
      // border-color: #7da9ae !important;
    
      background-color: #0069B1 !important;
      /* border: none !important; */
      border-radius: 15px !important;
      cursor: pointer !important;
      font: 12px Open Sans, sans-serif;
      height: 30px !important;
      text-decoration: none !important;
      margin-top: 3px !important;
      width: auto !important;
      border: 1px solid #0069B1;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-weight: 600;
      margin-right: 5px;
      margin-left: 5px;
      color: white;
    
      }

      .custom_button-ChangePS-Secondary:hover
      {
        background-color:   #014878 !important;
        color: white !important;
      }

      .custom_button-ChangePS-Primary:hover{
        background-color:  #7C9C3C !important;
        color: white !important;
      }
  
     




  .custom_button-login{
    // background-color: #026f7b !important;
    // border-color: #7da9ae !important;
  
      background: #0069b1 !important;
      color: #ffffff !important;

    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    text-transform: capitalize;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 10px;
    padding: 12px 30px;
    border: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 95% !important;

  
    }

    .custom_button-login:hover{
      background: #014878 !important ;
    }
    
  .custom_button_search{
    // background-color: #026f7b !important;
    // border-color: #7da9ae !important;
  
      background-color: #a9c23f;
      border: 1px solid #a9c23f;
      border-radius: 15px !important;
      cursor: pointer !important;
      float: right !important;
      font: 13px Open Sans, sans-serif !important;
      // height: 22px !important;
      margin-right: 4px !important;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
      -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      // padding: 0 10px !important;
      // text-decoration: none!important;

      // padding: 4px 19px !important;
      // color: #FFF !important;
      // text-align: center !important;
      // font-family: Open Sans !important;
      // font-size: 12px !important;
      // font-style: normal !important;
      // font-weight: 600 !important;
      // line-height: normal !important;
      // margin-bottom: 5px !important;


      color: #FFF !important;
      height: 27px !important;
      padding: 4px 19px !important;
      margin-left: 2px !important;
      margin-right: 2px !important;
      outline: none !important;
      // width: auto !important;
      cursor: pointer !important;
      outline: none !important;
      appearance: none !important;
      background: #a9c23f !important;
      border: 1px solid #a9c23f !important;
      text-align: center !important;
      border-radius: 15px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: normal !important;
      
  
    }

    .custom_button_search:hover{
      background:   #7C9C3C !important;
    }


  


  .btn:focus{
outline: none !important;
box-shadow: none !important;
  }


  // .custom_button:link{
  //   background-color: #7da9ae !important;
  //   border-color: #7da9ae !important; 
  //   } 


// .custom_button:hover{
// background-color: #7da9ae !important;
// border-color: #7da9ae !important;
// }

// .custom_button:active{
//   background-color: #7da9ae !important;
//   border-color: #7da9ae !important;
//   }
  

// .custom_button:focus{
//   background-color: #7da9ae !important;
//   border-color: #7da9ae !important;
//   }

  
// .custom_button:visited{
//   background-color: #7da9ae !important;
//   border-color: #7da9ae !important;
//   }
  

//   @media  (min-width: 576px){
//   .modal-dialog {
//       max-width: 80%;
//       margin: 1.75rem auto;
//   }
// }

// .modal.show .modal-dialog {
//     -webkit-transform: none;
//     transform: none;
//     width: 100%;
//     height: 80%;
// }

.nav-tabs {
    border-bottom: 1px solid;
    border-color: #c4c9d0;
    background: #e9e9e9;
    /* color: white; */
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: black !important;
    border-left: 1px solid #1182cf;
    border-right: 1px solid #1182cf;
    border-top: 1px solid #1182cf;
    padding: 6px 12px;
    border-radius: 5px 5px 0px 0px !important

}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: white !important;
    background-color: #73b6c2;
    border-color: #c4c9d0 #c4c9d0 #ebedef;
}




.bg-image {
  background: url('../assets/resource/backgroundimage.png') center center #0c3d88;
  background-size: cover;
  // background-repeat: round;
  // height: 100vh;
}



li.c-sidebar-nav-item{
    list-style-type:none;
   
}

.c-header .nav-link
{
  border: none !important;
}

.nav-item{
  margin-right: 2px !important;
}
.customtable-font{

  white-space: unset;
  text-align: left;
  font-weight: 400;
 // color: #065c8d;
  font-family: "Open Sans",Tahoma,Helvetica,Arial,sans-serif;
  font-size: 10px;
  padding: 3px 0 1px;
  width: 30%;
  line-height: 1.2;
  margin: 0 0 0 1%;
  box-sizing: border-box;



}

// .custom_label{
//   color: #066fa1;
//   font-size: 13px;
// }


input[type=date]:focus {
  outline: none !important;
    border:1px solid black;
    box-shadow: 0 0 10px white;
}

input[type=text]:focus {
  outline: none !important;
    border:1px solid black;
    box-shadow: 0 0 10px white;
}


option:focus {
  outline: none !important;
    border:1px solid black;
    box-shadow: 0 0 10px white;
}

.custom-select:focus {
  outline: none !important;
    border:1px solid black;
    box-shadow: 0 0 10px white;
}

  .custom_label {
  
  
  //white-space: nowrap;
  text-align: right;
  font-weight: normal;
  color: #1D57A1;
  font-family: 'Open Sans',Tahoma,Helvetica,Arial,sans-serif;
  font-size: 13px;
  padding: 3px 0px 1px 0px;
  line-height: 1.2;
  margin: 0px 0px 0px 1%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
 
  
  
    // color: #066fa1;
    // font-size: 13px;
    // text-align: right;
    display: grid;
  }

  


.required:after {
  content:" *";
  color: red;
}


@media  (max-width: 765px){
  .custom_label {
    display: flex;
  }
}


.headerBold{
  font-weight: bold;
  font-size: initial;
}

.labelbold{
  font-weight: bold;
  font-size: 13px;
  text-align: right;
  display: grid;

}


@media  (max-width: 765px){
  .labelbold {
    font-weight: bold;
    font-size: 13px;
    text-align: left;
    display: grid;
  }
  }


.custom_SENDTOPM:hover{
  background-color: #5392aa;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #414141;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
  border-radius: 5px;
  height: 23px;
}

.form-control-date
{
  width: auto !important;
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #414141;
  background-color: #fff;
  border-color: #d8dbe0;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}


.form-control:focus {
  color: #212529;
  background-color: #fff !important;
  // border-color: #25282c !important;
  border-color: #0069B1 !important;
  outline: 0 !important;
  box-shadow: none !important;
}

@media  (max-width: 1367px){
.col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
}
@media  (min-width: 1368px){
  .col-xxl, .col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    position: relative;
    width: 100%;
    padding-right: 1.5%;
    padding-left: 1.5%;
  }
}


.custom_tab{
background-color: #73b6c2 !important;
color: white !important;
}

.direction{
  flex-direction: row-reverse;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #73b6c2;
  border-color: #73b6c2;
}


.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  border-right: 1px solid;
  border-right-color: #d8dbe0;
}


.customHeadTitle{
  background: #DBEBFC;
  margin: inherit;
  border-radius: 10px;
  margin-top: 4px;
}

.customHeadTitle > h2 {
  color: #3371B3;
  font-size: revert;
  margin: 10px;
}



.customHeadButton{
  margin: auto;
  // display: flex;
}

// @media  (max-width: 765px){
//   .customHeadButton {
//  display: table;
//   }
// }


.customHeadButton > span{
  margin: auto;

}


.customHeadButton > button{
  background: #265fa5;
  color: white;
 font-size: xx-small;
 border: none;
 outline: none;
}

@media  (max-width: 765px){
  .pagination {
    overflow-x: auto;
    /* display: -webkit-flex; */
    display: flex;
    list-style: none;
    border-radius: 0.25rem;
  }
}

.valuelabel{
  display: block;
}




button.customFirstLast{
  position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid;
    color: #321fdb;
    background-color: #fff;
    border-color: #d8dbe0;
}

.card_login_image {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: none !important;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;

}

.card{
  // border: none  !important;
}
// .row {
//   display: -webkit-flex;
//   display: flex;
//   -webkit-flex-wrap: wrap;
//   flex-wrap: wrap;
//   margin-right: -15px;
//   margin-left: -15px;
//   margin-bottom: -15px;
// }


.form {
  display: block;
   height: fit-content;
  // flex-direction: row;
  // border: 1px solid grey;
  // padding: 2px;
}


.custom_input {
  flex-grow: 2;
  border: none;
}


// .custom_clear {
//   background-color: #026f7b;
//   border: none;
//   color: white;
//   text-align: center;
//   text-decoration: none;
//   display: inline-block;
//   cursor: pointer;
//   -webkit-transition-duration: 0.4s;
//   transition-duration: 0.4s;
// }

// .custom_clear:hover{
//   box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
// }


.divider {
  width: 1px;
  display: inline-block;
  margin-left: -53px;
}


.custom_clear {
  -webkit-align-items: center;
  align-items: center;
  background: #cecece;
  border-radius: 50%;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  height: 20px;
  margin: auto;
  -webkit-justify-content: center;
  justify-content: center;

}



button, input {
  overflow: visible;
  /* border: none !important; */
  outline: none;
}

button {
  border-radius: 0;
  border: none !important;
  outline: none !important;
}


.report_customize_table {
  white-space: unset;
  text-align: left;
  font-weight: 400;
  font-family: "Open Sans", Tahoma, Helvetica, Arial, sans-serif;
  font-size: 10px;
  padding: 3px 0 1px;
  width: 30%;
  line-height: 1.2;
  margin: 0 0 0 1%;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}



.Modelheader
{
  background: #73b6c2;
  color: white;
  font-size: large;
}

.workqueueheader{
  background: #73b6c2;
  color: white;
  font-size: large;
}


.providerenrollmentheader{
  // background: #2988E5;
  // color: white;
  font-size: large !important;
  // padding: 0.75rem 1.25rem;
  height: 45px !important;
}


.providerenrollmenttable td{
  padding: 0;
    vertical-align: top;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
}

.democlass{
  display: none !important;
}


.nav-link.active{
  background-color: #265fa5 !important;
color: white !important;
}


.followup_header{
  font-size: x-large;
  color: rgb(6, 111, 161);
}

.nav {
  padding-left: 0;
  border-bottom: 3px solid #265fa5
}

.customize_table{
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  overflow-x: auto;
  padding: 2px;
  text-align: center;
  background-color: #f1f1f1;
 
}

.customizeCard{
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
background-color: #DBEBFC;
}

.customERA li a {
  padding: 0px;
  color: #3c4b64;
  
 
    background-color: transparent;
    color: black
}


.mandatory::after{
  content:" *";
  color: red;
}

.customCheckbox{
  padding-left: 2rem !important;
}

.customizeRow {
  display: flex;
flex-wrap: wrap;
margin: auto;
}


.custom-select {
   font-size: 11px;
  
}

.GOBack{
  
  display:flex ;
  align-items: center !important;
  cursor: pointer !important;
  // background: #ebedef !important
}


button.disabled {
  cursor: default;
  color: #666666 !important;
  
}


#rightAlign{
  color: black;
  float: right;
  display: flex;
  align-items: center;
}

#rightAlign span{
  color: blue;
  font-weight: bold;
}

.dot {
  height: 15px;
  width: 15px;
  margin-right: 20px !important;
  border-radius: 50%;
  display: inline-block;
 
}


#toolTip1{
  color: black;
  background: #e4e0e0;
}

#tableFooter{
  float: right;
}

.LoginLabel{
  color: #5a5a5a;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0px !important;
}

.LoginField{
  // border: none;
  //   width: 100%;
  //   background-color: transparent !important;
  //   border-radius: 0;
  //   font-size: 14px;
  //   border-bottom: 1px solid #d1d1d1;

  border: none; 
  border-bottom: 1px solid #d1d1d1;
  width: 100%;
  background-color: transparent !important;
  font-size: 14px;
  margin-top: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 4px !important;
  background-color: #fbfbfb !important;
  color: #000;
  padding:  10px 7px !important;
  height: auto !important;

}


.CustomCard {

  height: 89%;
}

// .testing li a{
//   margin-bottom: -20px;
// }

.OutterCard , .InnerCard{
    
    margin-bottom: 1.5rem ;
    border-radius: 0.25rem ;
    background-color: #fff ;
    
}

.tableHeight{
  display: block;
  overflow: auto;
  height: 500px;
}

.modal-content{
  position: absolute;
    display: flex;
    flex-direction: column;
    width:fit-content;
    margin-left: -40%;
    

    
    background-clip: padding-box;
    border: 1px solid;
    border-radius: 0.3rem;
    outline: 0;
    border-top: none;
    background-color: #fff;
    border-color: rgba(0, 0, 21, 0.2);
    top: 50px;

    border: 3px solid #1D57A1 !important;
     border-radius: 10px !important;
    -moz-border-radius: 10px !important;
    -webkit-border-radius: 10px !important;

    
}

.modal-header
{
  padding: 7px 11px !important;
}

.modal-header::after{
  background: linear-gradient(to right, #F1B434 33%, #0069B1 33%, #0069B1 66%, #A9C23F 66%, #A9C23F 100%) !important;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 39px;
}


.modal-title{
  color: #1d57a1 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.errorLabel
{
  color: red;
  font-size: 11px;

}
.print{
  display: block; /* Not really needed in all cases */
  position: relative;
  width: auto;
  height: auto;
  overflow: visible;
}

// .table-responsive::-webkit-scrollbar {
//   display: none;
// }


// @media  (min-width: 750px){
//   .table-responsive::-webkit-scrollbar {
//     display: block !important;
//   }
// }


// td:nth-child(10)::-webkit-scrollbar-thumb{
//   // display: none;
//   background-color: #7da9ae ;
//   border-radius: 20px;
//   border: 3px solid black;

// }

// td:nth-child(10)::-webkit-scrollbar {
//   width: 12px;               /* width of the entire scrollbar */
// }

// td:nth-child(10)::-webkit-scrollbar-track {
//   background: #7da9ae;        /* color of the tracking area */
// }

/* ==========================================

   These are the final styles for the project.

   ========================================== */


/* =========================
   TOOLTIPS
   ========================= */

  [data-tooltip]{
    position: relative;
    cursor: default;
  }

  [data-tooltip]::after{
    position: absolute;
    width: 140px;
    left: calc(50% - 70px);
    bottom: 125%;
    text-align: center;
    box-sizing: border-box;

    content: attr(data-tooltip);
    color: white;
    background: black;
    padding: 8px;
    border-radius: 10px;
    font-size: 0.9em;
    font-weight: bold;

    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s, transform 0.2s;


  }

  
  [data-tooltip]:nth-child(1)::after{
    position: absolute;
    width: 140px;
    // left: calc(50% - 70px);
    // bottom: 125%;
    text-align: center;
    box-sizing: border-box;

    content: attr(data-tooltip);
    color: white;
    background: black;
    padding: 8px;
    border-radius: 10px;
    font-size: 0.9em;
    font-weight: bold;

    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s, transform 0.2s;


  }



  [data-tooltip]:hover::after{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }


  .VisitNavigationWrapper {
    display: flex;
    justify-content: end;
}

  .VisitNavigationLeft {
    color: black;
    background: white;
    margin-right: 15px;
}


.VisitNavigationRight {
  color: black;
  background: white;
}


.DashboardCElementTitle a{
  color: #808080;
  font-size: 14px;
}

.GetEligibility{
  // background: rgb(2, 111, 123);
  color: white !important;
  background-color: #0069B1 !important;
  /* border: none !important; */
  border-radius: 15px !important;
  cursor: pointer !important;
  font: 12px Open Sans, sans-serif;
  height: 30px !important;
  text-decoration: none !important;
  margin-top: 3px !important;
  width: 60% !important;
  border: 1px solid #0069B1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 600;

  
}

.showEligibility
{
  
  text-decoration: underline;
  
}

.showPayment
{
  text-decoration: underline;
  float: right;
}

@media screen and (max-width: 767px) {
  .smart-p {margin-right:0rem !important;}
  }




.card
{
  border: none !important;
  margin-bottom: 0 !important;
}


.OutterCard .card-body
{
  padding: 17px  !important;
  border: 1px solid #e6e6e6;
  border-radius: 8px;

}

.smart-p .card-group .card-body {
  
  padding: 12px 17px 0px 17px !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}




.form-control-datepicker{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #414141;
  background-color: #fff;
  border-color: red;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}






.form-group {
  margin-bottom: 0rem;
}



.k-i-more-vertical::before, .k-i-vbars::before {
  content: "\e129";
  }

  .k-grid tr.k-alt {
    background-color: white !important;
}
.k-grid td {
  border-color: white !important;
}
.k-pager-numbers .k-link.k-state-hover, .k-pager-numbers .k-link:hover {
  color: #000 !important;
  background-color: rgba(80,113,204,.08) !important;
}
.k-pager-numbers .k-link {
  color: #000 !important;
}
.k-pager-numbers .k-link.k-selected, .k-pager-numbers .k-link.k-state-selected {
  color: #ff6358 !important;
  background-color: rgba(255,99,88,.2) !important;
}
.k-grid {
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: 13px !important;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.pehr-login-header {
  position: absolute;
  right: 33px;
  top: 10px;
}
.login-version {
  padding: 23px 56px 25px 0;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
}
.pehrLogin_footer_wrape {
  width: 100%;
  bottom: 20px;
   position: absolute;
}
.pehrLogin_footer {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
}


.login-textfield{
  border: 1px solid #d1d1d1;
    border-radius: 4px;
    background-color: #fbfbfb !important;
    color: #000;
    padding: 2px 3px !important;
}


@media (min-width: 768px) {
  .col-md-8 {
      max-width: 52.66% !important;
  }
}

.bg-success
{
  background-color: #4a93e0 !important;
}

.text-success {
  color: black !important;
}

.workQueueCount-Color{
  color: black;
  background-color: white !important;
}

.c-footer{
  color: #0069B1 !important;
  background-color: #E9EFFB !important;
  flex: 0 0 26px !important;
  font-size: 10px !important;
  position: fixed !important;
  padding-top: 1px !important;
  height: 18px !important;
}

.messagePan {
  width: 99%;
  vertical-align: bottom;
  /* top: 0;
  left: 10px; */
  background: #f7f7f7;
  box-shadow: 0 1px 5px;
  margin: 0 auto;
  border-radius: 10px;
  position: absolute;
  top: 4px;
  left: 8px;
  z-index: 1;
}

.messagePan div.errorMessage {
  color: #c80101;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  line-height: normal;
  border-left: 10px solid #c80101;
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  
}



.emrgrid
{
     /* padding-top: 4px; */
     padding-left: 10px;
     color: #414141;
     /* font-weight: bold; */
     border-radius: 0;
     -moz-border-radius: 0;
     -webkit-border-radius: 0px 0px 10px 10px ;
     border: 2px solid #B7C8E2 ;
     border-top: white ;
     height: 37px;
     font-size: 14px;
 }

 .pGroup.w150 {
    width: 295px;
    text-align: right;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}   
.pGroup.w150 .pReload.pButton {
    margin-right: 5px;
}
.emrgrid div.pDiv div.pButton {
    float: left;
    width: 22px;
    height: 22px;
    border: 0px;
    cursor: pointer;
    overflow: hidden;
}
.emrgrid .pReload {
    background: url(../../src/assets/resource/icons/page/load.png) no-repeat center;
}
.emrgrid div.pDiv .pPageStat, .emrgrid div.pDiv .pcontrol {
    position: relative;
    top: 1px;
    overflow: visible;
    font-size: 12px;
}

.emrgrid div.pDiv {
    background-color: #fff;
    background-repeat: repeat-x;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display:flex;
    position:relative;
}
/* .pGroup.w150 {
    width: 200px;
    text-align:right;
    position:absolute;
    right:0;
} */
.emrgrid div.pDiv div.pDiv2 {
    font-family: "Open Sans",Tahoma,Helvetica,Arial,sans-serif;

    margin-left: -2px;
    float: left;
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: center;
}
#patientWaitListParentDiv .emrgrid div.pDiv div.pDiv2 {
    height: 25px;
}
#patientTrackerBoard .div.pDiv div.pDiv2 {
    height: 14px;
}
div.pGroup {
    font-family: "Open Sans",Tahoma,Helvetica,Arial,sans-serif;

    float: left;
    background: none;
    height: 20px;
    margin: 3px 5px;
    font-size: 12px;
}

.emrgrid div.pDiv .pPageStat,.emrgrid div.pDiv .pcontrol {
    font-family: "Open Sans",Tahoma,Helvetica,Arial,sans-serif;

    position: relative;
    top: 1px;
    overflow: visible;
}

.emrgrid div.pDiv input {
    font-family: "Open Sans",Tahoma,Helvetica,Arial,sans-serif;

    vertical-align: text-top;
    position: relative;
    top: -4px;
    margin: 3px;
    border: 1px solid #D1D1D1;
    font-size: 12px;
    color: #000;
    padding: 0px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
    height: 21px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    width: 50px;
}

.emrgrid div.pDiv select {
    margin: 3px;
}

/* .emrgrid div.pDiv  div.pButton {
    float: left;
    width: 22px;
    height: 22px;
    border: 0px;
    cursor: pointer;
    overflow: hidden;
} */

.emrgrid div.pDiv div.pButton:hover
    {
    width: 20px;
    height: 20px;
    border: 1px solid #D1D1D1;
    cursor: pointer;
        border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
}
.emrgrid div.pDiv  div.pButton.pBtnOver
    {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.emrgrid div.pDiv  div.pButton span {
    width: 20px;
    height: 20px;
    display: block;
    float: left;
}

.emrgrid div.pDiv  div.pButton:hover span
    {
    width: 19px;
    height: 19px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}
.emrgrid div.pDiv  div.pButton.pBtnOver span
    {
    width: 19px;
    height: 19px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
}
.emrgrid .pSearch {
    background: url(../../src/assets/resource/icons/page/magnifier.png) no-repeat center;
}

.emrgrid .pFirst {
    background: url(../../src/assets/resource/icons/page/first.png) no-repeat center;
}

.emrgrid .pPrev {
    background: url(../../src/assets/resource/icons/page/prev.png) no-repeat center;
}

.emrgrid .pNext {
    background: url(../../src/assets/resource/icons/page/next.png) no-repeat center;
}

.emrgrid .pLast {
    background: url(../../src/assets/resource/icons/page/last.png) no-repeat center;
}

.emrgrid .pReload {
    background: url(../../src/assets/resource/icons/page/loadPaginationBlue.png) no-repeat center;
}

.emrgrid .pReload.loading {
    background: url(../../src/assets/resource/icons/page/load.gif) no-repeat center;
}

/* ie adjustments */
.emrgrid.ie div.hDiv th div,.emrgrid.ie  div.bDiv td  div,div.colCopy.ie div
    /* common inner cell properties*/ {
    overflow: hidden;
}

 .emrgrid div.bDiv tr.trSelected td table tr td, .emrgrid div.bDiv tr.trSelected:hover td table tr td, .emrgrid div.bDiv tr.trSelected:hover td table tr td.sorted
{
background-image:none;
background-color:white;
}

.emrgrid div.bDiv tr.trSelected td table tr.erow td {
/*background: #e6f8ff;*/
border-bottom: 1px solid #f7f7f7;
}

.emrgrid div.bDiv tr.trSelected td table tr.erow td.sorted {
/*background: #C0EEFC;*/
border-bottom: 1px solid #e3e3e3;
}
.emrgrid div.bDiv input {
    font-size:12px;
}
.panContPBMDataGridTable{
   min-width:1700px;
}


@media screen and (max-width: 767px) {
  .pcontrol {
    display: none !important;
  }
  .emrgrid div.pDiv div.pDiv2 {

    justify-content: flex-start !important;
}
}


.badge-success
{
  background-color: transparent !important;
  font-size: 13px;
  font-weight: normal;
}

.c-header{
  border: none !important;
}


@media only screen and (max-width: 768px) {
  .headerHeightResp
  {
   height: auto !important; 
  }
  .c-sidebar{
    background: transparent !important; 
  } 
}

.c-wrapper .c-body .-c-main .c-container-fluid .row 
{
  background-color: #0069B1;
}

.dashboard-top-content{
  border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    box-shadow: 0px 1px 8px #d4dce4;
    -webkit-box-shadow: 0px 1px 8px #d4dce4;
    margin-bottom: 8px;
}

.dashboard-top-content-lable-link{

  text-decoration: underline;
  cursor: pointer;
  color: #3377BB;
  font-size: 18px;

}

.card-header
{
  margin-top: 12px;
  /* padding: 0.75rem 1.25rem; */
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: #fff;
  /* border-color: #d8dbe0; */
  border-radius: 7px !important;
  background: rgb(2, 111, 185);
  color: white;
  height:36px;
  padding: 0.5rem 1rem !important;
  font-size: 13.3333px;


}


.DashboardNewEra .c-sidebar-nav-link{
  justify-content: center;
  margin-right: 38px;
  color: #3377BB;
  outline: none;
  cursor: pointer;
  text-decoration: underline !important;
  padding: 0 !important;
}

.Dashboard-Content-ClMS .c-sidebar-nav-link{
  justify-content: center;
  margin-right: 38px;
  color: #3377BB;
  outline: none;
  cursor: pointer;
  text-decoration: none !important;
  padding: 0 !important;
  font-size: 25px;
}



.Dashboard-Content{
  font-size: 25px;
  margin: 0;
  color: #026fb9;

}

.dashboard-top-content a{
  color: #3377BB;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
  font-size: 18px;
} 

.card-group > .card
{
 border-radius: 10px 0px 0px 10px !important
}

@media screen and (max-width: 767px) {
  .card-group > .card {
    border-radius:10px !important
  }
}


.jss70
{
  background-color:#2988e5 !important ;
}

.c-main
{
  padding: 0.7rem !important;
}


@media (min-width: 992px) {
  .c-sidebar.c-sidebar-fixed {
      z-index: 0 !important;
  }
}

.ChangePasswordModal .modal-content
{
    border: 3px solid #1D57A1 !important;
    border-radius: 10px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
   width: 10px;
  height: 5px;
}


::-webkit-scrollbar-track {
  background: #eee;
  border: thin solid lightgray;
  box-shadow: 0px 0px 3px #dfdfdf inset;
  -webkit-box-shadow: 0px 0px 3px #dfdfdf inset;
  -moz-box-shadow: 0px 0px 3px #dfdfdf inset;
  border-radius: 6px;
}


::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border: thin solid gray;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #57a0cd;
}

.ChangePassModalLable{
  padding: 4px 17px 0px 0px;
  font-size: 0.7rem !important;
  font-weight: 600;
  line-height: 1.5;
  color: #1d57a1;
}

.fixed-label-container
{
  width: 123px;
  display: inline-block;
  text-align: end;
}

.invalid-feedback
{
  color: red !important;
  font-size: 13px !important;
}