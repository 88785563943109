

.k-grid td {

    border-width: inherit;
   
    }



 /* To set custom date filter and to hide kendo grid filter icon */
 .k-grid-header .k-grid-filter, .k-grid-header .k-header-column-menu {
    padding: 4px;
    width: calc(1.4285714286em + 10px);
    height: calc(1.4285714286em + 10px);
    box-sizing: border-box;
    outline: 0;
    line-height: 1.4285714286;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: absolute;
    right: 4px;
    bottom: 4px;
    z-index: 0;
  }

  /* .k-cell-inner .k-grid-filter, .k-cell-inner .k-header-column-menu, .k-cell-inner .k-hierarchy-cell .k-icon {
    color: #1d57a1;
    flex-shrink: 0;
    text-align: center;
} */

.k-grid .k-grid-filter, .k-grid .k-header-column-menu, .k-grid .k-grid-header-menu, .k-grid .k-hierarchy-cell .k-icon {
    color: #1d57a1 !important;
}

  
  /* .ExportExcel-icon
  {
    background-color: transparent !important;
  
  } */
  .ExportExcel-btn{
        padding: 2px 0px;
  }

  .k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-state-hover {
      color: rgb(247, 0, 0) !important;
      background-color: rgba(32, 255, 2, 0.08) !important;
      
  }
  
  .k-pager-numbers .k-link {
      color: black;
  }
  
  .k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
      background-color: #b4d6ff;
    
  }
  
  .k-pager-numbers .k-link.k-state-selected {
      color: white;
      background-color: #5392aa;
  }
  
  .k-grid tr.k-alt {
      background-color: white;
  }



.k-grid tr.k-alt, .k-grid tr:hover
{
     background-color: rgba(80, 113, 204, 0.08) ;
  
}

.k-grid tr.k-alt
{
    background-color: #f5f9fd  !important;
}


  .k-checkbox:checked, .k-checkbox.k-checked {
      border-color: #39f !important;
      color: white !important;
      background-color: #39f  !important;
  }
  .k-header, .k-grid-header {
 

      font-family: 'Open Sans', Tahoma, Helvetica, Arial, sans-serif  !important;
      font-size: 13px  !important;
      background: #2988E5 !important ;
      font-weight: 400 !important;
      border-right: 1px solid #297DD0  !;
      color: #fff;

  }



  .k-grid {
      border-width: 1px;
      border-style: solid;
      box-sizing: border-box;
      outline: 0;
      font-family: inherit;
      font-size: 13px;
      line-height: 1.4285714286;
      display: flex;
      flex-direction: column;
      position: relative;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      border: 2px solid #b7c8e2 !important;

      color: #000 ;
    
      border-top: white !important ;
      border-bottom: 3px solid #B7C8E2 !important ;
      /* border-radius: 0px 0px 8px 8px !important ; */
  

  }
  
  .k-grid a
  {
    color :#3377BB !important;
  }

    .customGridHeight {
      height: 500px;
  
  }
  
  .claim_content_heading {
      display: flex;
      width: inherit;
      float: inherit;
      padding: 0px 10px 0 5px;
      box-sizing: border-box;
      margin: 0;
      height: 32px;
      border: 2px solid #b7c8e2;
      border-bottom: #fff;
      border-radius: 0;
      -moz-border-radius: 0;
      -webkit-border-radius: 10px 10px 0 0;
       /* height: 42px; */
      /* background-color: #5392aa; */
        /* border-radius: 2px 2px 0 0; */
      /* -moz-border-radius: 2px 2px 0 0; */
      /* -webkit-border-radius: 2px 2px 0 0; */
    
      
  }
  
  .claim_content_heading h2{
      color: #414141;
     font-size: 14px !important;
     padding: 3px 4px !important;
     margin-top: 2px;
     font-weight: 700;

  }
  
  .k-i-more-vertical::before, .k-i-vbars::before {
      content: "\e129" !important;
      }
  
  @keyframes k-loading-animation  {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
  }
  
  .k-i-loading.k-example-loading {
      font-size: 64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: rgb(144,152,165);
  }
  
  .k-i-loading.k-example-loading::before,
  .k-i-loading.k-example-loading::after {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      content: "";
      box-sizing: inherit;
      border-radius: 50%;
      border-width: .05em;
      border-style: solid;
      border-color: currentColor;
      border-top-color: transparent;
      border-bottom-color: transparent;
      background-color: transparent;
  }
  .k-icon.k-i-loading.k-example-loading::before,
  .k-icon.k-i-loading::after {
      content: "";
  }
  
  .k-i-loading.k-example-loading::before {
      margin-top: -0.5em;
      margin-left: -0.5em;
      width: 1em;
      height: 1em;
      animation: k-loading-animation .7s linear infinite;
  }
  
  .k-i-loading.k-example-loading::after {
      margin-top: -0.25em;
      margin-left: -0.25em;
      width: .5em;
      height: .5em;
      animation: k-loading-animation reverse 1.4s linear infinite;
  }
  
  .example-wrapper {
      min-height: 280px;
      align-content: flex-start;
  }
  .example-wrapper p, .example-col p {
      margin: 20px 0 10px;
  }
  .example-wrapper p:first-child, .example-col p:first-child {
      margin-top: 0;
  }
  .example-col {
      display: inline-block;
      vertical-align: top;
      padding-right: 20px;
      padding-bottom: 20px;
  }
  .example-config {
      margin: 0 0 20px;
      padding: 20px;
      background-color: rgba(0,0,0,.03);
      border: 1px solid rgba(0,0,0,.08);
  }
  .event-log {
      margin: 0;
      padding: 0;
      max-height: 100px;
      overflow-y: auto;
      list-style-type: none;
      border: 1px solid rgba(0,0,0,.08);
      background-color: white;
  }
  .event-log li {
      margin: 0;
      padding: .3em;
      line-height: 1.2em;
      border-bottom: 1px solid rgba(0,0,0,.08);
  }
  .event-log li:last-child {
      margin-bottom: -1px;
  }

  .k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm, .k-grid-header .k-sort-order {
    color: #313131 !important;
}

.k-i-sort-desc-sm:before {
    content: "\e006" !important;
}

.k-i-sort-asc-sm:before {
    content: "\e004" !important;
}
